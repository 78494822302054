import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import compose from '../../../utils/compose/compose.js';
import styles from './SupportMessagePage.scss';
import { RvsLayoutConstants } from '../../../globals/rvsConstants';

import withLocalizedContent from '../../../language/withLocalizedContent';

import Button, { buttonTypes } from '../../../components/Button/Button';
import { Action } from '../../../components/ActionList/ActionList';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import forceAcceptBanner from '../../../utils/cookieBanner/forceAcceptBanner';
import { readInvalidCertChannel, addToRetryChannel } from '../../../redux/sagas/utils/rvs/certificateChannels';

function SupportMessagePage({ localizedContent: { supportMessagePage: content } }) {
  forceAcceptBanner();

  const [certificate, setCertificate] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const certData = readInvalidCertChannel();
    if (!certData) {
      navigate(RvsLayoutConstants.PATH_NAMES.HOME_PAGE);
    } else {
      setCertificate(certData);
    }
  }, []);

  const retryCertificate = () => {
    addToRetryChannel(certificate);
    navigate(RvsLayoutConstants.PATH_NAMES.MANUAL_FORM);
  };

  return (
    <div>
      <div className={styles.messageContainer}>
        <SVGIcon glyph={GLYPHS.ICON_WARNING_TRIANGLE} className={styles.warningIcon} />
        <h1>{content.error_text}</h1>
        <div className={styles.explainText}>
          {content.explain_text1}{' '}
          <a href={content.explain_link} target="_blank" rel="noreferrer">
            {content.explain_text2}
          </a>
        </div>
        <div className={styles.actions}>
          <Action className={styles.editButton} back label={content.edit_button} onClick={retryCertificate} />

          <Button
            type={buttonTypes.PRIMARY}
            text={content.check_certificate_button}
            to={RvsLayoutConstants.PATH_NAMES.HOME_PAGE}
          />
        </div>
      </div>
    </div>
  );
}

SupportMessagePage.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('supportMessagePage'))(SupportMessagePage);
