import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';

import LocationChangeListener from './locationChangeListener';
import ScrollToTop from './RVSLayout/ScrollToTop.js';

import RVSLayout from './RVSLayout';
import NotFoundView from '../views/errors/NotFoundView';
import ValidCertificatePage from '../routes/Rvs/ValidCertificatePage/ValidCertificatePage';
import InvalidCertificatePage from '../routes/Rvs/InvalidCertificatePage/InvalidCertificatePage';
import ErrorPage from '../routes/Rvs/ErrorPage/ErrorPage';
import SupportMessagePage from '../routes/Rvs/SupportMessagePage/SupportMessagePage';

// Blank page
import BlankPage from '../routes/BlankPage/BlankPage';
import RvsFormPage from '../routes/RvsFormPage/RvsFormPage';

import RvsSupportPage from '../routes/RvsSupport/RvsSupportPage';
import SupportArticlePage from '../routes/Support/SupportArticle/SupportArticlePage';

function RouteManager() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <LocationChangeListener>
        <Routes>
          {/* Blank page for loading core assets only, used for OUP.VIEW.embed prior to .gotoPage(...) */}
          <Route path="/blank" element={<BlankPage />} />

          {/* / path will load the rvs layout which will load the actual home page if it does not have any children */}
          <Route path="/" element={<RVSLayout />} />

          <Route
            path="/valid"
            element={
              <RVSLayout>
                <ValidCertificatePage />
              </RVSLayout>
            }
          />

          <Route
            path="/invalid"
            element={
              <RVSLayout>
                <InvalidCertificatePage />
              </RVSLayout>
            }
          />

          <Route
            path="/supportmessage"
            element={
              <RVSLayout>
                <SupportMessagePage />
              </RVSLayout>
            }
          />

          <Route
            path="/error"
            element={
              <RVSLayout>
                <ErrorPage />
              </RVSLayout>
            }
          />

          <Route
            path="/form"
            element={
              <RVSLayout>
                <RvsFormPage />
              </RVSLayout>
            }
          />

          {/* Support pages */}
          <Route
            path="/support/articles/:pageName?"
            element={
              <RVSLayout>
                <SupportArticlePage />
              </RVSLayout>
            }
          />

          <Route
            path="/support/:tabName?"
            element={
              <RVSLayout>
                <RvsSupportPage />
              </RVSLayout>
            }
          />

          {/* 404 */}
          <Route
            path="*"
            element={
              <RVSLayout>
                <NotFoundView />
              </RVSLayout>
            }
          />
        </Routes>
      </LocationChangeListener>
    </BrowserRouter>
  );
}

export default RouteManager;
