/* eslint-disable import/no-extraneous-dependencies */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from './PdfExporterFonts';
import { getLogo, getInvalidIcon, getValidIcon } from './PdfExporterImages';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const PdfExporter = async (glossary, formGlossary, formData, language, validity) => {
  pdfMake.fonts = {
    FiraSans: {
      normal: 'FiraSans-Regular.ttf',
      bold: 'FiraSans-Bold.ttf',
      italics: 'FiraSans-Italic.ttf',
      bolditalics: 'FiraSans-BoldItalic.ttf'
    }
  };

  const year = new Date().getUTCFullYear();
  const boxRadius = 3;
  const boxBorderColor = '#99A6B5';
  const boxBackgroundColor = '#FEFEFE';

  let boxWidth = 300;
  let boxContentsLeftMargin = 160;

  if (language === 'es') {
    boxWidth = 430;
    boxContentsLeftMargin = 95;
  }

  let mainText = glossary.error_text;
  let icon = getInvalidIcon();

  if (validity === 'valid') {
    mainText = glossary.valid_certificate_text;
    icon = getValidIcon();
  }

  let boxPositionAbsoluteTop = 272;
  if (language !== 'es' && validity === 'valid') {
    boxPositionAbsoluteTop = 250;
  }

  const docDefinition = {
    pageMargins: [0, 0, 0, 0],
    content: [
      {
        image: getLogo(),
        style: 'logo',
        width: 181,
        height: 42
      },
      {
        image: icon,
        style: 'icon',
        width: 40,
        height: 40
      },
      {
        text: mainText.replace(/(?![^\n]{1,32}$)([^\n]{1,32})\s/g, '$1\n'),
        style: 'header',
        width: 50
      },
      {
        text: glossary.explain_text_pdf.replace(/(?![^\n]{1,60}$)([^\n]{1,60})\s/g, '$1\n'),
        style: 'subheader'
      },
      {
        canvas: [
          {
            type: 'rect',
            x: 0,
            y: 0,
            w: boxWidth,
            h: 140,
            r: boxRadius,
            lineColor: boxBorderColor,
            color: boxBackgroundColor
          }
        ],
        margin: [0, 30, 0, 0],
        alignment: 'center'
      },
      {
        table: {
          body: [
            [
              [
                { text: formGlossary.test_taker_details_title, style: 'title' },
                { text: formGlossary.test_taker_name_label, style: 'label' },
                { text: formData.testTakerName, style: 'value' },
                { text: formGlossary.date_of_birth_label, style: 'label' },
                { text: formData.dateOfBirth, style: 'value' }
              ]
            ]
          ]
        },
        layout: 'noBorders',
        absolutePosition: { x: boxContentsLeftMargin, y: boxPositionAbsoluteTop }
      },
      {
        canvas: [
          {
            type: 'rect',
            x: 0,
            y: 0,
            w: boxWidth,
            h: 190,
            r: boxRadius,
            lineColor: boxBorderColor,
            color: boxBackgroundColor
          }
        ],
        margin: [0, 20, 0, 0],
        alignment: 'center'
      },
      {
        table: {
          body: [
            [
              [
                { text: formGlossary.certificate_details_title, style: 'title' },
                { text: formGlossary.test_taker_number_label, style: 'label' },
                { text: formData.testTakerNumber, style: 'value' },
                { text: formGlossary.certificate_reference_number_label, style: 'label' },
                { text: formData.certificateReferenceNumber, style: 'value' },
                { text: formGlossary.certificate_type_label, style: 'label' },
                {
                  text:
                    formData.certificateType === '1'
                      ? formGlossary.certificate_type_option_basic
                      : formGlossary.certificate_type_option_advanced,
                  style: 'value'
                }
              ]
            ]
          ]
        },
        layout: 'noBorders',
        absolutePosition: { x: boxContentsLeftMargin, y: boxPositionAbsoluteTop + 160 }
      },
      {
        canvas: [
          {
            type: 'rect',
            x: 0,
            y: 0,
            w: boxWidth,
            h: 140,
            r: boxRadius,
            lineColor: boxBorderColor,
            color: boxBackgroundColor
          }
        ],
        margin: [0, 20, 0, 200],
        alignment: 'center'
      },
      {
        table: {
          body: [
            [
              [
                { text: formGlossary.results_overview_title, style: 'title' },
                { text: formGlossary.overall_score_label, style: 'label' },
                { text: formData.overallScore, style: 'value' },
                { text: formGlossary.overall_cefr_level_label, style: 'label' },
                { text: formData.overallCefrLevel, style: 'value' }
              ]
            ]
          ]
        },
        layout: 'noBorders',
        absolutePosition: { x: boxContentsLeftMargin, y: boxPositionAbsoluteTop + 370 }
      },
      {
        canvas: [
          {
            type: 'rect',
            x: 0,
            y: 50,
            w: boxWidth,
            h: 634,
            r: 3,
            lineColor: boxBorderColor,
            color: boxBackgroundColor
          }
        ],
        margin: [0, 20, 0, 0],
        alignment: 'center'
      },
      {
        table: {
          body: [
            [
              [
                { text: formGlossary.module_results_title, style: 'title' },
                {
                  layout: 'noBorders',
                  table: {
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: formGlossary.speaking_score_label, style: 'label' },
                        { text: formGlossary.speaking_cefr_level_label, style: 'label' }
                      ],
                      [
                        { text: formData.speakingScore, style: 'value' },
                        { text: formData.speakingCefrLevel, style: 'value' }
                      ]
                    ]
                  }
                },
                { text: formGlossary.speaking_date_taken_label, style: 'label' },
                { text: formData.speakingDateTaken, style: 'value' },
                ...(formData?.speakingTestCentreID
                  ? [
                      { text: formGlossary.speaking_test_centre_id_label, style: 'label' },
                      { text: formData.speakingTestCentreID, style: 'value' }
                    ]
                  : []),
                {
                  layout: 'noBorders',
                  table: {
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: formGlossary.listening_score_label, style: 'label' },
                        { text: formGlossary.listening_cefr_level_label, style: 'label' }
                      ],
                      [
                        { text: formData.listeningScore, style: 'value' },
                        { text: formData.listeningCefrLevel, style: 'value' }
                      ]
                    ]
                  }
                },
                { text: formGlossary.listening_date_taken_label, style: 'label' },
                { text: formData.listeningDateTaken, style: 'value' },
                ...(formData?.listeningTestCentreID
                  ? [
                      { text: formGlossary.listening_test_centre_id_label, style: 'label' },
                      { text: formData.listeningTestCentreID, style: 'value' }
                    ]
                  : []),
                {
                  layout: 'noBorders',
                  table: {
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: formGlossary.reading_score_label, style: 'label' },
                        { text: formGlossary.reading_cefr_level_label, style: 'label' }
                      ],
                      [
                        { text: formData.readingScore, style: 'value' },
                        { text: formData.readingCefrLevel, style: 'value' }
                      ]
                    ]
                  }
                },
                { text: formGlossary.reading_date_taken_label, style: 'label' },
                { text: formData.readingDateTaken, style: 'value' },
                ...(formData?.readingTestCentreID
                  ? [
                      { text: formGlossary.reading_test_centre_id_label, style: 'label' },
                      { text: formData.readingTestCentreID, style: 'value' }
                    ]
                  : []),
                {
                  layout: 'noBorders',
                  table: {
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: formGlossary.writing_score_label, style: 'label' },
                        { text: formGlossary.writing_cefr_level_label, style: 'label' }
                      ],
                      [
                        { text: formData.writingScore, style: 'value' },
                        { text: formData.writingCefrLevel, style: 'value' }
                      ]
                    ]
                  }
                },
                { text: formGlossary.writing_date_taken_label, style: 'label' },
                { text: formData.writingDateTaken, style: 'value' },
                ...(formData?.writingTestCentreID
                  ? [
                      { text: formGlossary.writing_test_centre_id_label, style: 'label' },
                      { text: formData.writingTestCentreID, style: 'value' }
                    ]
                  : [])
              ]
            ]
          ]
        },
        layout: 'noBorders',
        absolutePosition: { x: boxContentsLeftMargin, y: 50 }
      },
      {
        text: [`© ${year} Oxford University Press`],
        absolutePosition: { x: 440, y: 820 },
        style: 'footer'
      }
    ],
    defaultStyle: {
      font: 'FiraSans'
    },
    styles: {
      logo: {
        alignment: 'left',
        margin: [20, 20, 0, 0]
      },
      icon: {
        alignment: 'center',
        margin: [0, 40, 0, 0]
      },
      header: {
        fontSize: 22,
        bold: true,
        alignment: 'center',
        margin: [0, 10, 0, 0]
      },
      subheader: {
        fontSize: 11,
        alignment: 'center',
        margin: [0, 10, 0, 0]
      },
      title: {
        fontSize: 15,
        alignment: 'left',
        margin: [0, 10, 0, 0],
        color: '#333'
      },
      label: {
        fontSize: 11,
        bold: true,
        alignment: 'left',
        margin: [0, 15, 0, 0],
        color: '#333'
      },
      value: {
        fontSize: 11,
        alignment: 'left',
        margin: [0, 5, 0, 0],
        color: '#333'
      },
      footer: {
        fontSize: 10,
        color: '#555'
      }
    }
  };

  pdfMake.createPdf(docDefinition).download('CertificateRecord.pdf');
};

export default PdfExporter;
