import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import compose from '../../../utils/compose/compose.js';
import styles from './ValidCertificatePage.scss';
import { RvsLayoutConstants } from '../../../globals/rvsConstants';
import actions from '../../../redux/actions';

import withLocalizedContent from '../../../language/withLocalizedContent';
import { readValidCertChannel } from '../../../redux/sagas/utils/rvs/certificateChannels';

import Button, { buttonTypes } from '../../../components/Button/Button';
import RvsForm from '../../../components/RvsForm/RvsForm';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import PdfExporter from '../PdfExporter/PdfExporter';
import { getLanguage } from '../../../utils/manageSelectorLanguage';

function ValidCertificatePage({
  reset,
  localizedContent: { validCertificatePage: content, rvsFormPage: formContent }
}) {
  const [formData, setFormData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const certData = readValidCertChannel();
    if (!certData) {
      navigate(RvsLayoutConstants.PATH_NAMES.HOME_PAGE);
    } else {
      setFormData(certData);
    }
  }, []);

  if (!formData) {
    return false;
  }

  const checkNewCertificate = () => {
    reset();
    navigate(RvsLayoutConstants.PATH_NAMES.HOME_PAGE);
  };

  return (
    <div>
      <div className={styles.messageContainer}>
        <Helmet title={content.valid_certificate_head} />
        <div id="messageContainer">
          <SVGIcon glyph={GLYPHS.ICON_CHECK_CIRCLE} />
          <h1>{content.valid_certificate_text}</h1>
          <div className={styles.explainText}>{content.explain_text}</div>
        </div>
        <div className={styles.actions}>
          <Button
            customClassName={styles.downloadButton}
            type={buttonTypes.DOWNLOAD}
            text={content.download_button}
            glyph={GLYPHS.ICON_DOWNLOAD}
            hidePostIcon
            onClick={() => {
              const currentLanguage = getLanguage();
              PdfExporter(content, formContent, formData, currentLanguage, 'valid');
            }}
          />
          <Button
            customClassName={styles.checkButton}
            type={buttonTypes.PRIMARY}
            text={content.check_certificate_button}
            onClick={checkNewCertificate}
          />
        </div>
      </div>
      {formData && <RvsForm data={formData} customClassName={styles.form} readOnly displayBodyOnly />}
    </div>
  );
}

ValidCertificatePage.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  reset: PropTypes.func
};

export default compose(
  withLocalizedContent('validCertificatePage', 'rvsFormPage'),
  connect(
    () => {},
    dispatch => ({
      reset: () => {
        dispatch(actions.rvsFormReset());
      }
    })
  )
)(ValidCertificatePage);
