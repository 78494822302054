import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from '../../utils/compose/compose.js';
import withLocalizedContent from '../../language/withLocalizedContent';
// Style
import styles from './RVSLayout.scss';
import SimpleHeaderStyles from '../StatePages/SimpleHeader/SimpleHeader.scss';
// Redux
// Utils
import withBreakpoint from '../../decorators/withBreakpoint';
// Components
import ErrorPage from '../StatePages/ErrorPage/ErrorPage.js';
import HubHeadersAndBanners from '../HubHeader/HubHeadersAndBanners';
import LoadingPage from '../StatePages/LoadingPage/LoadingPage.js';
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon.js';
import HubFooter from '../HubFooter/HubFooter.js';
import RvsHomepage from '../../routes/RvsHomepage/RvsHomepage';
import RvsLiveChat from '../../components/RvsLiveChat/RvsLiveChat';
// Features
import evaluateLayoutProps from './Services/evaluateLayoutProps';
import ReCAPTCHA from '../../components/ReCAPTCHA/ReCAPTCHA';

function RVSLayout({
  children,
  appError,
  appErrorReference,
  appReady,
  breakpoint,
  localizedContent: { rvsFooterGlossary: hubFooterContent }
}) {
  const [headerProps, setHeaderProps] = useState({});
  const [footerProps, setFooterProps] = useState({});

  useEffect(() => {
    const THEME_NAME = 'rvs-theme';
    document.body.setAttribute('data-theme', THEME_NAME);

    const pathname = window.location.pathname;
    const result = evaluateLayoutProps({
      pathname
    });
    setHeaderProps(result.headerProps);
    setFooterProps(result.footerProps);
  }, []);

  const customLogoComponent = (
    <div className={SimpleHeaderStyles.hubLogo}>
      <SVGIcon glyph={GLYPHS.OUP_LOGO_BLUE} />
    </div>
  );

  if (appError) {
    return <ErrorPage appErrorReference={appErrorReference} customLogoComponent={customLogoComponent} />;
  }
  if (!appReady) {
    return <LoadingPage customLogoComponent={customLogoComponent} />;
  }
  const shouldHideLanguageSelector =
    ['/form', '/valid', '/invalid', '/supportmessage'].indexOf(window.location.pathname) >= 0;

  const headerPropsExtended = {
    ...headerProps,
    hasPendingInvites: false,
    hideRegisterLink: true,
    hideSignInLink: true,
    hideLanguageSelector: shouldHideLanguageSelector,
    breakpoint
  };

  const footerPropsExtended = {
    ...footerProps,
    hubFooterContent: { ...hubFooterContent },
    breakpoint,
    scrollReachBottom: false
  };

  // Due to the structure of the router and the absolute need to have / as the homepage route we check
  // if we have any children, and if none are defined we actually render the rvs homepage
  const childrenToRender = children || <RvsHomepage />;

  return (
    <ReCAPTCHA>
      <div className={styles.rvsApp}>
        <div>
          <HubHeadersAndBanners {...headerPropsExtended} />
          <div className={styles.pageContainer}>
            <main id="maincontent">{childrenToRender}</main>
            <RvsLiveChat />
            <HubFooter {...footerPropsExtended} />
          </div>
        </div>
      </div>
    </ReCAPTCHA>
  );
}

RVSLayout.propTypes = {
  children: PropTypes.any,
  localizedContent: PropTypes.object.isRequired,
  appError: PropTypes.bool.isRequired,
  appErrorReference: PropTypes.string,
  appReady: PropTypes.bool.isRequired,
  userSignedIn: PropTypes.bool.isRequired,
  userId: PropTypes.string,
  role: PropTypes.string,
  breakpoint: PropTypes.string
};

export default compose(
  connect(({ app: { appError, appErrorReference, appReady }, identity: { userId = '', role } }) => ({
    appError,
    appErrorReference,
    appReady,
    userId,
    role,
    userSignedIn: !!role
  })),
  withLocalizedContent('rvsFooterGlossary'),
  withBreakpoint
)(RVSLayout);
